<template>
  <div class="create-perk">
    <SupplierAppbar />
    <div class="ma-4">
      <SupplierForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Supplier
 * ==================================================================================
 **/

import { mapMutations } from 'vuex'
import SupplierAppbar from '@/views/Home/Maintenance/Suppliers/components/SupplierAppbar'
import SupplierForm from '@/views/Home/Maintenance/Suppliers/components/SupplierForm'

export default {
  components: {
    SupplierAppbar,
    SupplierForm,
  },

  created() {
    this.clearDetails()
  },

  methods: {
    ...mapMutations({
      clearDetails: 'supplier/clearSupplierDetails',
    }),
  },
}
</script>
