import { render, staticRenderFns } from "./SupplierDetails.vue?vue&type=template&id=0381e708&scoped=true"
import script from "./SupplierDetails.vue?vue&type=script&lang=js"
export * from "./SupplierDetails.vue?vue&type=script&lang=js"
import style0 from "./SupplierDetails.vue?vue&type=style&index=0&id=0381e708&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0381e708",
  null
  
)

export default component.exports