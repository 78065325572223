<template>
  <div class="supplier-details py-8">
    <SupplierAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="ma-4">
          <v-col cols="12" md="4">
            <ViewingTitle
              :title="displayName"
              class="supplier-details__title"
            />
          </v-col>
          <v-col cols="12" md="1" class="text-right">
            <SupplierArchive
              v-if="hasDeletePermissions"
              :model="supplier"
              name="Supplier"
            />
          </v-col>
        </v-row>
        <div class="ma-4">
          <div class="mb-6">
            <SupplierForm
              class="supplier-details__form"
              :supplier="supplier"
              :is-update="true"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Supplier Request
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import SupplierAppbar from '@/views/Home/Maintenance/Suppliers/components/SupplierAppbar'
import SupplierForm from '@/views/Home/Maintenance/Suppliers/components/SupplierForm'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import SupplierArchive from '@/views/Home/Maintenance/Suppliers/components/SupplierArchive'
import ViewingTitle from '@/components/common/ViewingTitle.vue'

export default {
  name: 'SupplierDetails',

  components: {
    ViewingTitle,
    SupplierArchive,
    SupplierAppbar,
    SupplierForm,
    Loading,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      supplier: (state) => state.supplier.supplierDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return 'Info'
    },

    hasDeletePermissions() {
      return validatePermissions(
        [PERMISSION.SUPPLIERS_DELETE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getSupplierDetails: 'supplier/getSupplierDetails',
    }),

    ...mapMutations({
      clearSupplierDetails: 'supplier/clearSupplierDetails',
    }),

    async getSupplier() {
      this.loading = true
      await this.getSupplierDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getSupplier()
  },

  destroyed() {
    this.clearSupplierDetails()
  },

  watch: {
    $route() {
      this.getSupplier()
    },
  },
}
</script>

<style lang="scss" scoped>
.supplier-details {
  &__title {
    padding-right: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  &__divider {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
